<template>
  <vx-card class="main-box md:w-1/2 top-zero-radius">
    <vs-row>
      <vs-col>
        <span class="text-sm">{{ $t('accountancy.documents.insert.labels.documentType') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="types" :default="selectedType" v-model="selectedType"/>
      </vs-col>
    </vs-row>

    <vs-row v-if="selectedUser" class="mt-2">
      <vs-col>
        <span class="text-sm">{{ selectedType.value === "receive" ? $t('accountancy.documents.insert.labels.receiveFrom') : $t('accountancy.documents.insert.labels.paymentTo') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="users" :default="selectedUser" v-model="selectedUser"/>
      </vs-col>
    </vs-row>

    <vs-row class="mt-2">
      <vs-col>
        <span class="text-sm">{{ $t('accountancy.documents.insert.labels.price') }}</span>
      </vs-col>

      <vs-col>
        <vs-input class="w-full price-style"
                  @input="addComma()"
                  v-model="newDocument.price"/>
      </vs-col>
    </vs-row>

    <vs-row class="mt-2">
      <vs-col>
        <span class="text-sm">{{ selectedType.value === "receive" ? $t('accountancy.documents.insert.labels.depositTo') : $t('accountancy.documents.insert.labels.withdrawalFrom') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="cashOrBank" :default="selectedCashOrBank" v-model="selectedCashOrBank"/>
      </vs-col>
    </vs-row>

    <vs-row class="mt-2" v-if="selectedCashOrBank.value === 'cash'">
      <vs-col>
        <span class="text-sm">{{ $t('accountancy.documents.insert.labels.cashBox') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="cashes" :default="selectedCashes" v-model="selectedCashes"/>
      </vs-col>
    </vs-row>

    <vs-row class="mt-2" v-if="selectedCashOrBank.value === 'bank'">
      <vs-col>
        <span class="text-sm">{{ $t('accountancy.documents.insert.labels.bank') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="banks" :default="selectedBanks" v-model="selectedBanks"/>
      </vs-col>
    </vs-row>

    <vs-row class="mt-2" v-if="selectedType.value === 'receive' && (selectedBanks.value !== 0 || selectedCashes.value !== 0)">
      <vs-col>
        <span class="text-sm">{{ $t('accountancy.documents.insert.labels.depositType') }}</span>
      </vs-col>

      <vs-col>
        <custom-select :options="paymentTypes" :default="selectedPaymentType" v-model="selectedPaymentType"/>
      </vs-col>
    </vs-row>

    <vs-button id="insertDocumentBTN" @click="insertDocument" class="useral-action-button"/>
  </vx-card>
</template>

<script>
import CustomSelect from '@/components/customSelect/customSelect'
import {getUsers} from '@/http/requests/users/users'
import {addComma} from '@/assets/js/functions'
import {getBanks} from '@/http/requests/banks'
import {getCashBoxes} from '@/http/requests/cashBoxes'
import {insertPayment, insertReceive} from '@/http/requests/documents'
export default {
  name: 'insertDocument',
  metaInfo () {
    return {
      title: this.$t('accountancy.documents.insert.title')
    }
  },
  components: {CustomSelect},
  data () {
    return {
      types: [
        {
          label: this.$t('accountancy.documents.insert.labels.receive'),
          value: 'receive'
        },
        {
          label: this.$t('accountancy.documents.insert.labels.payment'),
          value: 'payment'
        }
      ],
      typesCopy: [
        {
          label: this.$t('accountancy.documents.insert.labels.receive'),
          value: 'receive'
        },
        {
          label: this.$t('accountancy.documents.insert.labels.payment'),
          value: 'payment'
        }
      ],
      selectedType: {},
      users: [],
      usersCopy: [],
      selectedUser: null,
      cashOrBank: [
        {
          label: this.$t('accountancy.documents.insert.labels.choose'),
          value: 0
        },
        {
          label: this.$t('accountancy.documents.insert.labels.cashBox'),
          value: 'cash'
        },
        {
          label: this.$t('accountancy.documents.insert.labels.bank'),
          value: 'bank'
        }
      ],
      cashOrBankCopy: [
        {
          label: this.$t('accountancy.documents.insert.labels.choose'),
          value: 0
        },
        {
          label: this.$t('accountancy.documents.insert.labels.cashBox'),
          value: 'cash'
        },
        {
          label: this.$t('accountancy.documents.insert.labels.bank'),
          value: 'bank'
        }
      ],
      selectedCashOrBank: {},
      cashes: [],
      cashesCopy: [],
      selectedCashes: {},
      banks: [],
      banksCopy: [],
      selectedBanks: {},
      paymentTypes: [
        {
          label: this.$t('accountancy.documents.insert.labels.choose'),
          value: 0
        },
        {
          label: this.$t('accountancy.documents.insert.labels.pos'),
          value: 1
        },
        {
          label: this.$t('accountancy.documents.insert.labels.cardToCard'),
          value: 2
        },
        {
          label: this.$t('accountancy.documents.insert.labels.shaba'),
          value: 3
        }
      ],
      paymentTypesCopy: [
        {
          label: this.$t('accountancy.documents.insert.labels.choose'),
          value: 0
        },
        {
          label: this.$t('accountancy.documents.insert.labels.pos'),
          value: 1
        },
        {
          label: this.$t('accountancy.documents.insert.labels.cardToCard'),
          value: 2
        },
        {
          label: this.$t('accountancy.documents.insert.labels.shaba'),
          value: 3
        }
      ],
      selectedPaymentType: {},
      newDocument: {
        price: 0
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertDocumentBTN',
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'documents'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 m-0 mx-auto')
    }, 50)

    if (this.$route.query.hasOwnProperty('type') && this.$route.query.type === 'payment') this.selectedType = this.types[1]
    else this.selectedType = this.types[0]


    this.users.push({
      label: this.$t('accountancy.documents.insert.labels.choose'),
      value: 0
    })
    getUsers().then(response => {
      const users = response.data.data
      users.forEach((user) => {
        this.users.push({
          label: `${user.name  } ${  user.family}`,
          value: user.id
        })
      })
      this.usersCopy = this.users

      if (this.$route.query.hasOwnProperty('user')) {
        const user_index = this.users.map((elm) => { return elm.value }).indexOf(parseInt(this.$route.query.user))
        this.selectedUser = this.users[user_index]
      } else {
        this.selectedUser = this.users[0]
      }
    })

    this.selectedCashOrBank = this.cashOrBank[0]

    this.banks.push({
      label: this.$t('accountancy.documents.insert.labels.choose'),
      value: 0
    })
    this.selectedBanks = this.banks[0]
    getBanks().then(response => {
      const banks = response.data.data
      banks.forEach((bank) => {
        this.banks.push({
          label: bank.name,
          value: bank.id
        })
      })
      this.banksCopy = this.banks
    })

    this.cashes.push({
      label: this.$t('accountancy.documents.labels.insert.choose'),
      value: 0
    })
    this.selectedCashes = this.cashes[0]
    getCashBoxes().then(response => {
      const cashes = response.data.data
      cashes.forEach((cash) => {
        this.cashes.push({
          label: cash.name,
          value: cash.id
        })
      })
      this.cashesCopy = this.cashes
    })

    this.selectedPaymentType = this.paymentTypes[0]

  },
  methods: {
    addComma () {
      this.newDocument.price = addComma(this.newDocument.price)
    },
    insertDocument () {
      if (this.selectedType.value === 'receive') {
        const receive = {
          user_id: this.selectedUser.value,
          price: this.newDocument.price.toString().replaceAll(',', '')
        }
        if (this.selectedCashOrBank.value === 'cash' && this.selectedCashes.value !== 0) {
          receive.cash_id = this.selectedCashes.value
        }
        if (this.selectedCashOrBank.value === 'bank' && this.selectedBanks.value !== 0) {
          receive.bank_id = this.selectedBanks.value
        }
        if (this.selectedPaymentType.value !== 0) {
          receive.how = this.selectedPaymentType.value
        }

        if (receive.user_id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.user'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (parseInt(receive.price) === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.user'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.depositTo'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 'cash' && this.selectedCashes.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.cashBox'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 'bank' && this.selectedBanks.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.bank'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedPaymentType.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.depositType'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        insertReceive(receive).then(response => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('accountancy.documents.insert.notifications.receive.insert.success'),
            time: 2400,
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$router.push({name: 'documents'})
        }).catch(error => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.receive.insert.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
      } else {
        const payment = {
          user_id: this.selectedUser.value,
          price: this.newDocument.price.toString().replaceAll(',', '')
        }
        if (this.selectedCashOrBank.value === 'cash' && this.selectedCashes.value !== 0) {
          payment.cash_id = this.selectedCashes.value
        }
        if (this.selectedCashOrBank.value === 'bank' && this.selectedBanks.value !== 0) {
          payment.bank_id = this.selectedBanks.value
        }

        if (payment.user_id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.user'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (parseInt(payment.price) === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.price'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.withdrawalFrom'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 'cash' && this.selectedCashes.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.cashBox'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        if (this.selectedCashOrBank.value === 'bank' && this.selectedBanks.value === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.parseError.bank'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          return false
        }

        insertPayment(payment).then(response => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('accountancy.documents.insert.notifications.payment.insert.success'),
            time: 2400,
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$router.push({name: 'documents'})
        }).catch(error => {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.documents.insert.notifications.payment.insert.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        })
      }
    }
  },
  watch: {
    selectedBanks: {
      handler (val, oldVal) {
        const banks = []
        for (let i = 0; i < this.banksCopy.length; i++) {
          if (this.banksCopy[i].value !== 0 && this.banksCopy[i].value !== this.selectedBanks.value) {
            banks.push(this.banksCopy[i])
          }
        }
        this.banks = banks
      },
      deep: true
    },
    selectedCashes: {
      handler (val, oldVal) {
        const cashes = []
        for (let i = 0; i < this.cashesCopy.length; i++) {
          if (this.cashesCopy[i].value !== 0 && this.cashesCopy[i].value !== this.selectedCashes.value) {
            cashes.push(this.cashesCopy[i])
          }
        }
        this.cashes = cashes
      },
      deep: true
    },
    selectedPaymentType: {
      handler (val, oldVal) {
        const paymentTypes = []
        for (let i = 0; i < this.paymentTypesCopy.length; i++) {
          if (this.paymentTypesCopy[i].value !== 0 && this.paymentTypesCopy[i].value !== this.selectedPaymentType.value) {
            paymentTypes.push(this.paymentTypesCopy[i])
          }
        }
        this.paymentTypes = paymentTypes
      },
      deep: true
    },
    selectedCashOrBank: {
      handler (val, oldVal) {
        const cashOrBank = []
        for (let i = 0; i < this.cashOrBankCopy.length; i++) {
          if (this.cashOrBankCopy[i].value !== 0 && this.cashOrBankCopy[i].value !== this.selectedCashOrBank.value) {
            cashOrBank.push(this.cashOrBankCopy[i])
          }
        }
        this.cashOrBank = cashOrBank
      },
      deep: true
    },
    selectedType: {
      handler (val, oldVal) {
        const types = []
        for (let i = 0; i < this.typesCopy.length; i++) {
          if (this.typesCopy[i].value !== 0 && this.typesCopy[i].value !== this.selectedType.value) {
            types.push(this.typesCopy[i])
          }
        }
        this.types = types
      },
      deep: true
    },
    selectedUser: {
      handler (val, oldVal) {
        const users = []
        for (let i = 0; i < this.usersCopy.length; i++) {
          if (this.usersCopy[i].value !== 0 && this.usersCopy[i].value !== this.selectedUser.value) {
            users.push(this.usersCopy[i])
          }
        }
        this.users = users
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
